<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title
                class="justify-center"
                style="word-break: break-word; font-size: 18px; padding: 8px 24px 8px;"
            >
                <v-spacer/>
                Forma de pagamento selecionada:

                <v-chip class="ma-2" color="primary" label small>
                    <v-icon left>mdi-credit-card-outline</v-icon>
                    Cartão de Crédito
                </v-chip>

                <v-spacer/>
            </v-card-title>

            <div class="pay-message">
                Ao realizar assinatura com cartão de crédito,
                a aprovação poderá levar até no máximo 5 minutos.
                Desde já lhe desejamos muito sucesso, conte com a gente!
            </div>

            <v-card-text align="center" justify="center">
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step step="1">
                        Dados do assinante
                        </v-stepper-step>

                        <v-divider/>

                        <v-stepper-step step="2">
                        Confirmação
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form
                                ref="form"
                                style="height: 275px; overflow-y: auto; padding: 0 12px 12px 0;"
                            >
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Nome Completo"
                                            v-model="form.nome"
                                            :rules="[rules.empty, rules.fullName]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="E-mail"
                                            v-model="form.email"
                                            :rules="[rules.email]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Telefone"
                                            v-model="form.telefone"
                                            :rules="[rules.empty]"
                                            prefix="+55"
                                            placeholder="(99) 9 9999-9999"
                                            v-mask="['(##) # ####-####']"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="CPF"
                                            v-model="form.cpf"
                                            :rules="[rules.empty, rules.cpf]"
                                            v-mask="['###.###.###-##']"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Data de Nascimento"
                                            v-model="form.dataNascimento"
                                            :rules="[rules.empty]"
                                            type="date"
                                            placeholder="DD/MM/YYYY"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            @keypress.enter="consultaCep"
                                            @blur="consultaCep"
                                            v-model="form.cep"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            :loading="loading.cep"
                                            label="CEP"
                                            v-mask="['#####-###']"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            :items="ufs"
                                            v-model="form.uf"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            label="UF"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Município"
                                            v-model="form.municipio"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Bairro"
                                            v-model="form.bairro"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="8">
                                        <v-text-field
                                            label="Rua"
                                            v-model="form.rua"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            label="Número"
                                            v-model="form.numero"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>

                            <div style="display: flex; justify-content: space-between;">
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="#1fc625"
                                    class="white--text"
                                    @click="continuar"
                                >
                                    Continuar
                                    <v-icon>mdi-arrow-right</v-icon>
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-form ref="pagamentoCartao">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Número do Cartão"
                                            v-model="card.number"
                                            v-mask="[cardMask]"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                        <div style="text-align: left;">{{ cardType }}</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Titular do Cartão"
                                            v-model="card.name"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        Validade do Cartão
                                        <div style="display: flex;">
                                            <v-select
                                                v-model="card.expirationMonth"
                                                :items="meses"
                                                label="Mes"
                                                :rules="[rules.empty]"
                                                outlined
                                                dense
                                                hide-details
                                            ></v-select>
                                            <v-select
                                                v-model="card.expirationYear"
                                                :items="anos"
                                                label="Ano"
                                                :rules="[rules.empty]"
                                                outlined
                                                dense
                                                hide-details
                                            ></v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6" style="display: flex; align-items: flex-end;">
                                        <v-text-field
                                            v-model="card.cvv"
                                            label="CVV"
                                            :rules="[rules.empty]"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>

                            <div style="display: flex; justify-content: space-between; margin-top: 15px;">
                                <v-btn text color="warning" @click="step = 1">Voltar</v-btn>
                                <v-btn
                                    large
                                    color="#1fc625"
                                    class="white--text"
                                    :loading="loading.assinar"
                                    @click="assinar"
                                >Assinar</v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ufs from '@/utils/ufs';
import { getPaymentToken } from '@/plugins/gn';

export default {
    name: 'AssinaturaCartao',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        empresa: {
            type: Object,
        },
    },

    data: () => ({
        formaPagamento: 'cartao', // essa informacao vira pela props

        loading: { assinar: false, cep: false},
        step: 1,
        ufs: [],
        meses: [],
        anos: [],
        form: {},
        card: {},
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            fullName: value => (value || '').trim().split(' ').length > 1 || 'Informe o nome completo',

            cpf: value => value?.length == 14 || 'Cpf incompleto',
        },
    }),

    computed: {
        cardType() {
            const number = this.card.number || '';

            if (number.match(new RegExp('^4')) != null) {
                return 'visa';
            }

            if (number.match(new RegExp('^(34|37)')) != null) {
                return 'amex';
            }

            if (number.match(new RegExp('^5[1-5]')) != null) {
                return 'mastercard';
            }

            if (number.match(new RegExp('^6011')) != null) {
                return 'discover';
            }

            if (number.match(new RegExp('^9792')) != null) {
                return 'troy'
            }

            return ''; // default type
        },

        cardMask() {
            return this.cardType === 'amex'
                ? '#### ###### #####'
                : '#### #### #### ####';
        },
    },

    watch: {
        visible(v) {
            v && this.initialize();
        },

        empresa(v) {
            v && (this.form = {...this.form, ...v});
        },
    },

    methods: {
        initialize() {
            this.fillOptions();
        },

        fillOptions() {
            this.ufs = ufs;
            this.meses = [
                '01', '02', '03', '04', '05', '06',
                '07', '08', '09', '10', '11', '12',
            ];
            const year = new Date().getFullYear();
            for (let i = 0; i < 10; i++) {
                this.anos.push((year + i).toString());
            }
        },

        continuar() {
            if (this.$refs.form.validate()){
                this.step = 2;
                return;
            }

            this.notify('Preencha todos os campos', 'warning');
        },

        async assinar() {
            if (!this.$refs.pagamentoCartao.validate()) {
                this.notify('Preencha todos os campos', 'warning');
                return;
            }

            const card =  {
                brand: this.cardType,
                number: this.card.number,
                cvv: this.card.cvv,
                expiration_month: this.card.expirationMonth,
                expiration_year: this.card.expirationYear
            };

            this.loading.assinar = true;
            await getPaymentToken(card).then(resp => {
                this.form.paymentToken = resp.data.payment_token;
            })
            .catch(e => {
                alert(`${e.error} - ${e.error_description}`);
            })
            .finally(() => (this.loading.assinar = false));

            this.efetuarPagamento();
        },

        efetuarPagamento() {
            this.loading.assinar = true;
            this.$http.post('assinaturas', this.form).then(resp => {
                const data = resp.data.data;

                if (data.type == 'warning') {
                    alert(data.msg);
                    return;
                }

                this.$emit('paid');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading.assinar = false));
        },

        async consultaCep() {
            if (!this.form.cep || this.form.cep == '') {
                return;
            }

            const cep = this.form.cep.replace(/\D/g, '');
            let validacep = /^[0-9]{8}$/;

            if (!validacep.test(cep)) {
                alert('Informe um cep válido');
                // this.notify('Informe um cep válido', 'warning');
                return;
            }

            this.loading.cep = true;
            const request = await fetch(`https://viacep.com.br/ws/${cep}/json`, { method: 'GET', });
            let resp = await request.json();

            this.form.uf = resp.uf;
            this.form.bairro = resp.bairro;
            this.form.municipio = resp.localidade;
            this.form.rua = resp.logradouro;

            this.loading.cep = false;
        },
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.pay-message {
    font-size: 14px;
    color: #aaa;
    text-align: center;
    padding: 0 10px 10px 20px;
}
</style>
