<template>
    <v-dialog v-model="visible" persistent max-width="800px" style="padding-bottom: 10px;">
        <v-card>
            <v-card-title
                class="justify-center"
                style="word-break: break-word; font-size: 18px; padding: 8px 24px 8px;"
            >
                <v-spacer></v-spacer>
                Forma de pagamento selecionada:

                <v-chip class="ma-2" color="primary" label small>
                    <v-icon left>mdi-barcode</v-icon>
                    Boleto/Pix
                </v-chip>

                <v-spacer></v-spacer>
            </v-card-title>

            <div class="pay-message">
                Você pode escolher a forma de pagamento na hora de quitar o boleto:
                a forma tradicional pelo código de barras ou Pix através do QR Code<br>
            </div>

            <v-card-text
                align="center"
                justify="center"
                style="border: 1px solid #aaa; border-radius: 5px; padding: 10x 0 0 0;"
            >
                <div class="ma-1">Escolha uma opção para continuar:</div>
                <v-btn-toggle v-model="form.tipoPessoa" rounded color="primary">
                    <v-btn value="pf">
                        Pessoa Física
                    </v-btn>
                    <v-btn value="pj">
                        Pessoa Jurídica
                    </v-btn>
                </v-btn-toggle>

                <v-form v-if="form.tipoPessoa" ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-if="form.tipoPessoa === 'pf'"
                                label="Nome Completo"
                                v-model="form.nome"
                                :rules="[rules.empty, rules.fullName]"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-else
                                label="Razão Social"
                                v-model="form.corporateName"
                                :rules="[rules.empty]"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="E-mail"
                            v-model="form.email"
                            :rules="[rules.email]"
                            outlined
                            dense
                            hide-details
                        />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-if="form.tipoPessoa === 'pf'"
                                label="CPF"
                                v-model="form.cpf"
                                :rules="[rules.empty, rules.cpf]"
                                v-mask="['###.###.###-##']"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-else
                                label="CNPJ"
                                v-model="form.cnpj"
                                :rules="[rules.empty, rules.cnpj]"
                                v-mask="['##.###.###/####-##']"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Whatsapp"
                                v-model="form.whatsapp"
                                :rules="[rules.empty]"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-form>

                <div style="display: flex; justify-content: space-between;">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="form.tipoPessoa"
                        large
                        color="#1fc625"
                        class="white--text"
                        :loading="loading.assinar"
                        @click="assinar"
                    >Assinar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AssinaturaBolix',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        empresa: {
            type: Object,
        },
    },

    data: () => ({
        loading: { assinar: false, cep: false},
        step: 1,
        form: {
            tipoPessoa: null,
        },
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            fullName: value => (value || '').trim().split(' ').length > 1 || 'Informe o nome completo',

            cpf: value => value?.length >= 14 || 'Cpf incompleto',

            cnpj: value => value?.length >= 18 || 'Cnpj incompleto',
        },
    }),

    watch: {
        empresa(v) {
            v && (this.form = {...this.form, ...v});
        },
    },

    methods: {
        continuar() {
            if (this.$refs.form.validate()) {
                this.step = 2;
                return;
            }

            this.notify('Preencha todos os campos', 'warning');
        },

        async assinar() {
            if (!this.form.tipoPessoa) {
                this.notify('Selecione o Tipo de Pessoa', 'warning');
                return;
            }

            if (!this.$refs.form.validate()) {
                this.notify('Preencha todos os campos', 'warning');
                return;
            }

            this.loading.assinar = true;
            this.$http.post('assinaturas', this.form).then(resp => {
                const data = resp.data.data;

                if (data.type == 'warning') {
                    alert(data.msg);
                    return;
                }

                this.notify('Assinatura emitida com sucesso!');

                this.$emit('paid', data);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading.assinar = false));
        },
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 15px 15px;
}

.v-dialog > .v-card > .v-card__text {
    padding: 10px 24px 20px;
}

.pay-message {
    font-size: 14px;
    color: #aaa;
    text-align: center;
    padding: 0 10px 10px 20px;
}
</style>
