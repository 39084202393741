
const installGerenciaNet = function () {
    const url = (process.env.NODE_ENV === "production" && process.env.VUE_APP_SANDBOX !== "true")
      ? "https://api.gerencianet.com.br/v1/cdn/"
      : "https://sandbox.gerencianet.com.br/v1/cdn/";

    const integrationId = "588bd8c04d668994821396ab030ad190";

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = false;
    s.id = integrationId;
    const v = parseInt(Math.random() * 1000000);
    s.src = `${url}${integrationId}/${v}`;

    if (!document.getElementById(integrationId)) {
        document.getElementsByTagName("head")[0].appendChild(s);
    }
    window.$gn = {
        validForm: true,
        processed: false,
        done: {},
        ready: function (fn) {
            window.$gn.done = fn;
        },
    };
}

const getGnCheckout =  async function () {
    return new Promise((resolve) => {
        if (window.$gnCheckout) {
            resolve(window.$gnCheckout);
            return;
        }
        installGerenciaNet();
            window.$gn.ready(function (checkout) {
            window.$gnCheckout = checkout;
            resolve(checkout);
        });
    });
}

export async function getPaymentToken (card) {
    const checkout = await getGnCheckout();
    return new Promise((resolve, reject) => {
        checkout.getPaymentToken(card, function (error, response) {
            if (error) {
                reject(error);
            } else {
                resolve(response);
            }
        });
    });
}
